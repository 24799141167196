import React from "react";
import "./styles/Navbar.css";
import { Link } from "react-router-dom";
import newppp from "./images/newppp.png";

function Navbar() {
  return (
    <nav className="navbar">
      <img src = {newppp} alt = "logo" className = "nav-actual-logo" />
      <Link to="/" className="no-underline">
        <div className="navbar-logo">Passion Pathways</div>
      </Link>
      <ul className="navbar-list">
        <li className="navbar-link">
          <Link to="/" className="no-underline">
            Home
          </Link>
        </li>
        <li className="navbar-link">
          <Link to="/about-us" className="no-underline">
            About Us
          </Link>
        </li>
        <li className="navbar-link">
          <Link to="/pricing" className="no-underline">
            Pricing
          </Link>
        </li>
        <li className="navbar-link">
          <a
            href="mailto:admin@passionpathways.com"
            className="no-underline"

          >
            Contact Us
          </a>
        </li>
        <button className="nav-started-button">
                <Link
                  to="https://calendly.com/passionpathways/consultation?month=2024-06"
                  className="button-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get started
                </Link>
              </button>
        {/* <li className="navbar-link">
          <Link to="/donate" className="no-underline">
            Donate
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default Navbar;
