import React from 'react'
import './styles/HomeTutor.css'
import { Link } from 'react-router-dom'

const HomeTutor = ({image, tutorName, location, major, college, collegeClass, url, external}) => {

  const Wrapper = external ? 'a' : Link;
  const linkProps = external ? { href: url, target: '_blank', rel: 'noopener noreferrer' } : { to: url };

  return (
    <Wrapper {...linkProps} className="home-tutor-link">
    <div className="HomeTutor">
        <div className="tutor-image-container">
        <img className="tutor-image" src={image} alt='ti' />
      </div>
        <div className="tutor-container">
        <div className = "tutor-name">{tutorName}</div>
        <div className="tutor-location">
        <p>{location}</p>
        </div>
        <div className = "tutor-major">
        <p>{major}</p>
        </div>
        <div className={`tutor-college-container ${collegeClass}`}>
        <img className = "tutor-college" src = {college} alt = 'tc'/>
        </div>
      </div>
    </div>
    </Wrapper>
  )
}


export default HomeTutor