import { Auth } from '@supabase/auth-ui-react';
import {
  ThemeSupa,
} from '@supabase/auth-ui-shared';
import { createClient } from '@supabase/supabase-js';
import React from 'react';
import './styles/Login.css';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_ANON_KEY
);

const Login = () => (
  <div className='login-container'>
    <h1 className='form-heading'>Your journey starts now...</h1>
    <Auth
      supabaseClient={supabase}
      appearance={{
        theme: ThemeSupa,
        style: {
          button: {
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid black',
            padding: '1.5em', // Ensure the custom padding is applied
            fontSize: '1.2em', // Ensure the custom font size is applied
          },
          input: {
            borderRadius: '5px',
            border: '1px solid #ccc',
            padding: '1.5em', // Ensure the custom padding is applied
            fontSize: '1.2em', // Ensure the custom font size is applied
          },
        }
      }}
      providers={['google']}
    />
  </div>
);

export default Login;

