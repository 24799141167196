import React from "react";
import "./styles/Donate.css";
import imagedonate from "./images/Donate_home.webp";

function Donate() {
  return (
    <div className="donate-background">
      <div className="don-background-background">
        <div className="don-background">
          <div className="donate-container">
            <img src={imagedonate} alt="donate" className="donate-image" />
            <div>space</div>
            <div>space</div>
            <div>space</div>
            <div>space</div>
            <div>space</div>
            <div>space</div>

            <div className="donate-content">Donate</div>

            <div className="donate-box">
              <div className="donate-text">
                <h1 className="donate-heading">
                  Your donations help us provide services to low-income and
                  underrepresented students. We accept online donations via
                  credit/debit card. Click the button below to donate.
                </h1>
              </div>
              <a
                href="https://buy.stripe.com/test_dR68xq5101EWbjqcMM"
                className="no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="donate-btn">DONATE NOW</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
