import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";

import AboutUs from "./AboutUs";
import Login from "./Login";
import Navbar from "./Navbar";
import Pricing from "./Pricing"
import Mentor from "./MentorPage";
import Donate from "./Donate";
import Payment from "./Payment";
import Completion from "./Completion";
import Footer from "./Footer";


const Main = () => {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== '/login' && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path = "/pricing" element = {<Pricing />} />
        <Route path="/mentor" element={<Mentor />} />
        <Route path="/login" element={<Login />} />
        <Route path ="/donate" element={<Donate />} />
        <Route path="/" element={<Payment />} />
          <Route path="/completion" element={<Completion />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default Main;
