import React from 'react'
import "./styles/Pricing.css"
import { Link } from "react-router-dom";
function Pricing() {
  return (
    <div className = "pricing-background-container">
      <div className = "pricing-container">
        <div className = "pricing-title">Flexible pricing that fits</div>
        <div className = "pricing-square">
          <div className = "pricing-square-title">per session</div>
          <div className = "first-price">$90</div>
          <div className = "first-price-subtitle">per session</div>
          <div className='first-price-description'>We recommend mentees meet with their mentors on a consistent weekly basis, as this ensures a productive and rewarding mentorship experience. We offer a $90 per one-hour session as an alternative to our bundle model.

‍</div>
          <div classsName = 'first-price-description-list'>
            <p className = "first-price-list-title">You'll receive:</p>
            <ul className = "price-list">
              <li>
             Sessions with a 1:1 mentor, hand-picked to be the best fit for your student
              </li>
              <li>
              Support in identifying and pursuing unique project opportunities

              </li>
              <li>
              Regular progress reports to keep you updated on your student’s development

              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className = "pricing-sec-container">
        <div className = "pricing-sec-square">
          <div className = "pricing-sec-square-title">per bundle</div>
          <div className = "first-sec-price">$305</div>
          <div className = "first-sec-price-subtitle">per bundle</div>
          <div className='first-sec-price-description'>We recommend mentees meet with their mentors on a consistent weekly basis, as we’ve seen this cadence produces the most productive, connected, meaningful mentorship experience. However, we get that a monthly subscription may not be ideal for every family. That’s why we offer pay-as-you-go pricing at $90 per one-hour session as an alternative to our subscription model.

‍</div>
          <div classsName = 'first-sec-price-description-list'>
            <p className = "first-sec-price-list-title">With our bundle model, you'll get:</p>
            <ul className = "price-sec-list">
              <li>
              Perks: $20 savings/session, additional sessions at an exclusive rate of $90/session
              </li>
              <li>
              Evaluation time: No commitment, 1-week evaluation period to ensure you’re happy with your mentor. (Session billed at $90/hour during evaluation.)
              </li>
            </ul>
          </div>
        </div>
        <button className="pricing-started-button">
                <Link
                  to="https://calendly.com/passionpathways/consultation?month=2024-06"
                  className="button-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get started
                </Link>
              </button>
      </div>

    </div>
  )
}

export default Pricing