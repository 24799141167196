import React from 'react';
import './styles/Mentor.css';

const MentorPage = () => {
  return (
    <div className="mentor-page">
      <header className="header">
        <h1>Mentorship</h1>
        <h2>1:1 Mentorship for Kids K-12</h2>
      </header>
      <section className="content">
        <div className="info-box">
          <h3>What We Offer</h3>
          <p>Mentorship for kids to grow their ideas into projects (passion projects).</p>
        </div>
        <div className="shape-box"></div>
      </section>
    </div>
  );
};

export default MentorPage;
