import React from "react";
import "./styles/Home.css";
import { Link } from "react-router-dom";
import imagehome from "./images/homeentrepeneur.png";
import HomeTutor from "./HomeTutor";
import duke1 from "./images/duke1.png";
import chicago1 from "./images/chicagopng.png";
import mallika from "./images/mallika.jpg";
import christian from "./images/christian.jpg";
import PlaceholderImages from "./PlaceholderImages";
import PlaceholderImagesCaptions from "./PlaceholderImagesCaptions";
import acceptance from "./images/acceptance.png";
import callvid from "./images/callvid.mp4";
function Home() {
  return (
    <div>
      <div className="background-container">
        <div className="dimmed-background"></div>
        <div className="white-box">
          <div className="content">
            <div className="text-content">
              <div className = "before-main">Paving extracurriculars into pathways to success</div>
              <h1 className="main-heading">
              Progress fueled by tailored mentorship.
              </h1>
              <h3 className="sub-heading">Your student can:</h3>
              <ul className="student-list">
                <li>Be virtually mentored 1-on-1 by undergrads at top universities</li>
                <li>
                  Bring their idea to life with a business and/or STEM mentor
                </li>
                <li>Stand out in competitive college admissions</li>
              </ul>
              <button className="get-started-button">
                <Link
                  to="https://calendly.com/passionpathways/consultation?month=2024-06"
                  className="button-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get paired
                </Link>
              </button>
            </div>
            <div className="image-container">
              <img src={imagehome} alt="Entrepreneur" className="home-image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mentors-title-container">
        <h1 className="mentors-title">
          Guidance from mentors who excel at top universities.
        </h1>
      </div>
      <div className="mentor-cards-background">
        <div className="mentor-cards-container">
          <HomeTutor
            image={christian}
            tutorName="Christian H."
            location="Cary, NC"
            major="Class of 2027"
            college={chicago1}
            collegeClass="chicago-logo"
            url="https://www.linkedin.com/in/christian-herrera-tenorio-7b298826b/"
          />
          <HomeTutor
            image={mallika}
            tutorName="Mallika K."
            location="Alpharetta, GA"
            major="Class of 2025"
            college={duke1}
            url="https://www.linkedin.com/in/mallika-kenkare/"
          />
          <HomeTutor
            image={imagehome}
            tutorName="Biruk"
            location="San Francisco, CA"
            major="Business Administration"
            college={duke1}
          />
        </div>
      </div>
      <h1 className="passion-title">Bring your passion to life.</h1>
      <PlaceholderImages />
      <PlaceholderImagesCaptions />
      <div className="value-background">
        <h1 className="value-title">Extracurriculars get acceptance letters.</h1>
        <div className="value-container">
          <p>
          Approximately 75% of students applying to top-tier universities, such as the Ivy League schools, have exceptional grades and test scores. However, what differentiates the top 5% of applicants who secure admission are their unique extracurricular activities, leadership experiences, and personal achievements.
          </p>
          <img src={acceptance} alt="acceptance" className="impress-image" />
        </div>
      </div>
      <div className = "testimonial-background">
        <h1 className = "testimonial-title">What people are saying:</h1>
      <div className = "testimonial-container">
       <div className = "first-testimonial-square">
        <div className = "first-testimonial-description">"Passion Pathways has been a game-changer for me. Through their mentorship program, I not only got the chance to dive deep into my research idea but also received invaluable advice on careers in STEM. Passion Pathways mentors are truly dedicated to helping students like myself succeed!"</div>
        <div className = "first-testimonial-name">- Sofia L</div>
        <div className = "first-testimonial-grade">Grade 11</div>
       </div>
       <div className = "second-testimonial-square">
       <div className = "second-testimonial-description">"I was amazed by the level of guidance and support I received from Passion Pathways. Being mentored by likeminded college students not only helped me develop my business idea but also gave me valuable insights into what it takes to succeed in the college application process."</div>
       <div className = "second-testimonial-name">- Diego M</div>
       <div className = "second-testimonial-grade">Grade 9</div>
       </div>
       <div className = "third-testimonial-square">
       <div className = "third-testimonial-description">"Passion Pathways provided me with the mentorship and resources I needed to turn my business idea into a reality. Their hands-on approach helped me develop important skills and refine my pitch, making me more confident in presenting my ideas.”</div>
       <div className = "third-testimonial-name">- Mei C</div>
       <div className = "third-testimonial-grade">Grade 10</div>
       </div>
      </div>
      </div>
      < div className = "call-to-action-background">
        <div className = "call-to-action-video-container">
 <a href="https://calendly.com/passionpathways/consultation?month=2024-06" target="_blank" rel="noopener noreferrer">
        <video src = {callvid} className = "call-to-action-video" autoPlay loop muted/>

        <div className = "overlay">
        <div className = "video-prev-title">Ready to take the first step?</div>
        <h1 className = "video-title" >Book a consultation call today.</h1>

        </div>
        </a>


        </div>
        </div>
    </div>
  );
}

export default Home;
