import React from 'react';
import './styles/Home.css';
import growth from './images/growth.png'; // Replace with actual placeholder image paths
import scientist from './images/scientist.png'; // Replace with actual placeholder image paths
import programming from './images/programming.png'; // Replace with actual placeholder image paths

const PlaceholderImages = () => {
  return (
    <div className="placeholder-images-container">
      <div className="placeholder-image-card">
        <img src={growth} alt="Placeholder 1" className="placeholder-image" />
        <p className="caption">Build a Business</p>

      </div>
      <div className="placeholder-image-card">
        <img src={scientist} alt="Placeholder 2" className="placeholder-image" />
        <p className="caption">Publish Research</p>

      </div>
      <div className="placeholder-image-card">
        <img src={programming} alt="Placeholder 3" className="placeholder-image" />
        <p className="caption">Code an Application</p>

      </div>

    </div>
  );
};

export default PlaceholderImages;
