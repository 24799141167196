import React from 'react'
import './styles/Home.css'

function PlaceholderImagesCaptions() {
  return (
    <div className = "caption-container-p">
        <div className = "caption-uno">For aspiring entrepreneurs, connect with our business mentors who have studied and deployed successful products and services. </div>
        <div className = "caption-dos">Let our STEM mentors guide your student through the process of formulating and publishing research papers and scientific inquiries. </div>
        <div className = "caption-tres">Creating a successful website or application from scratch can be difficult to navigate, our computer science mentors can walk your student through the process.</div>
    </div>
  )
}

export default PlaceholderImagesCaptions