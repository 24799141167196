import React from 'react'

import './styles/AboutUs.css'

import tempabout from './images/temp-about.jpeg'
function AboutUs() {
  return (
    <div className = "about-container">
    <div className = "motiv-header">Transforming ambition into achievement.</div>
    <div className = "about-sec-background">
    <div className = "about-info">
    <div className = "mission-container">Our mission is to drive student success through expert mentorship. We are committed to empowering the next generation of innovators by providing them with personalized guidance from top students at prestigious universities as well as helping them follow in their footsteps.</div>
    <div className = "mentor-mission-container">Our mentors are carefully selected from Ivy League and other top-tier institutions, ensuring they bring a wealth of knowledge, experience, and passion to their roles. Each mentor is dedicated to helping students discover and develop impressive extracurricular activities and unique projects that enhance their college applications. With their firsthand experience of excelling in academia and beyond, our mentors provide invaluable insights and support.</div>
    <img src = {tempabout} alt = "about" className = "about-image"/>
    </div>
    </div>
    </div>
  )
}

export default AboutUs